import React from "react";
import Image from "web/components/atoms/Image";
import RichTextComponent from "web/components/atoms/RichText/RichText";
import classNames from "classnames";
import Btn from "web/components/atoms/Btn";
import { useLinkResolver } from "web/hooks";

const TextImage = ({ content }) => {
  if (!content) return;

  const { title, text, image, btn_label, btn_link, image_position, with_bg } =
    content.primary;

  const url =
    btn_link.link_type === "Document"
      ? useLinkResolver(btn_link)
      : btn_link.url;

  const classes = classNames({
    "text-image": true,
    "with-bg": with_bg,
  });

  return (
    <>
      <div className={`${classes} ${image_position}`}>
        <div className={`text-image__inner ctn ctn-large`}>
          <div className="text-image__image">
            <div className="text-image__image__inner">
              <Image image={image} />
            </div>
          </div>
          <div className="text-image__content">
            <h3 className="text-image__title h2">{title}</h3>
            <div className="text-image__text richtext">
              <RichTextComponent content={text} />
            </div>
            {btn_label && url ? (
              <div className="text-image__actions">
                {btn_link.link_type === "Document" ? (
                  <Btn to={url}>{btn_label}</Btn>
                ) : (
                  <Btn to={url} type="web" target="_blank">
                    {btn_label}
                  </Btn>
                )}
              </div>
            ) : null}
          </div>
        </div>

        <svg class="filter" xmlns="http://www.w3.org/2000/svg">
          <defs>
            <filter id="filter">
              <feGaussianBlur
                in="SourceGraphic"
                stdDeviation="8"
                result="blur"
              />
              <feColorMatrix
                in="blur"
                mode="matrix"
                values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9"
                result="flt_tag"
              />
              <feComposite in="SourceGraphic" in2="filter" operator="atop" />
            </filter>
          </defs>
        </svg>
      </div>
    </>
  );
};

export default TextImage;
