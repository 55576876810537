import React from "react";

const Review = ({ data }) => {
  return (
    <div className="block-reviews__card">
      <div className="block-reviews__card__name">{data.title}</div>
      <div className="block-reviews__card__category">{data.subtitle}</div>
      <div className="block-reviews__card__comment">{data.comment}</div>
    </div>
  );
};

export default Review;
