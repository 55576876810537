import React from "react";
import RichTextComponent from "web/components/atoms/RichText/RichText";
import classNames from "classnames";
import Icon from "web/components/atoms/Icon";

const FaqItem = ({ item }) => {
  const [active, setActive] = React.useState(false);
  const [maxHeight, setMaxHeight] = React.useState(0);

  const handleClick = () => {
    setActive(!active);
  };

  const contentRef = React.useRef(null);

  React.useEffect(() => {
    setMaxHeight(active ? `${contentRef.current.scrollHeight}px` : 0);
  }, [active]);

  const tabClass = classNames({
    faqs__item: true,
    active: active,
  });

  const contentStyles = {
    maxHeight: maxHeight,
  };

  return (
    <div className={tabClass}>
      <div onClick={handleClick} className="faqs__item__label">
        {item.question}
        <div className="faqs__item__icon">
          {active ? <Icon icon="minus" /> : <Icon icon="plus" />}
        </div>
      </div>
      <div
        className="faqs__item__content"
        style={{ ...contentStyles }}
        ref={contentRef}
      >
        <div className="faqs__item__content__inner">
          <blockquote>
            <RichTextComponent content={item.answer} />
          </blockquote>
        </div>
      </div>
    </div>
  );
};

const Faqs = ({ content }) => {
  const { items, primary } = content;

  const { title } = primary;

  return (
    <div className="faqs">
      <div className={`faqs__inner ctn ctn-large`}>
        <h2 className="h2">{title}</h2>
        <div className="faqs__items">
          {items.map((item, key) => {
            return <FaqItem key={key} item={item} />;
          })}
        </div>
      </div>
    </div>
  );
};

export default Faqs;
