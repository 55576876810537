import React from "react";
import sampleImg from "web/assets/img/teamwork.png";
import Btn from "web/components/atoms/Btn";

const Card = ({ number, label }) => {
  return (
    <div className="block-presentation__card">
      <span className="block-presentation__card__size">{number}+</span>
      <span className="block-presentation__card__label">{label}</span>
    </div>
  );
};

export default function Presentation({ content }) {
  const {
    title,
    text,
    image,
    label_1,
    label_2,
    number_1,
    number_2,
    button_label,
    button_link,
    text_color,
    bg_color,
    block_size,
  } = content.primary;

  const style = {
    color: text_color,
  };

  if (bg_color) {
    style.backgroundColor = bg_color;
  }
  return (
    <div className="block-presentation" style={style}>
      <div className={`block-presentation__inner ctn ctn-${block_size}`}>
        <div className="block-presentation__image">
          <picture>
            <source srcSet="" media="" />
            <img src={image.url} alt={image.alt} />
          </picture>
        </div>
        <div className="block-presentation__content">
          <h2 className="block-presentation__content__title">{title}</h2>
          <p className="block-presentation__content__description">{text}</p>
          <div className="block-presentation__content__cards">
            <Card number={number_1} label={label_1} />
            <Card number={number_2} label={label_2} />
          </div>
          <Btn>{button_label}</Btn>
        </div>
      </div>
    </div>
  );
}
