export default {
  spaceBetween: 0,
  slidesPerView: 1,
  loop: true,
  speed: 1000,
  navigation: true,
  pagination: {
    clickable: true,
  },
};
