import React, { useRef } from "react";
import Btn from "web/components/atoms/Btn/Btn";
import Icon from "web/components/atoms/Icon";
import Slider from "web/components/organisms/Slider/Slider";
import Review from "./Review";
import sliderConf from "./sliderConf";
import {
  isMobile,
  isTablet,
} from "web/components/helpers/mediaQueries/mediaQueries";

const Reviews = ({ content }) => {
  const { items, primary } = content;
  const { title, button_label, button_link, block_size } = primary;
  const [slidesPerView, setSlidesPerView] = React.useState(3);

  React.useEffect(() => {
    setSlidesPerView(isMobile ? 1 : isTablet ? 2 : 3);
  }, []);

  return (
    <>
      <div className="block-reviews">
        <div className={`block-reviews__inner ctn ctn-${block_size}`}>
          <h2 className="block-reviews__title">{title}</h2>
          <div className="block-reviews__cards">
            <Slider
              sliderConf={{
                ...sliderConf,
                slidesPerView: slidesPerView,
                navigation: {
                  prevEl: ".reviews-prev",
                  nextEl: ".reviews-next",
                },
              }}
              slider
              items={items}
              ItemComponent={Review}
              slideId="reviews"
            />
          </div>
          <div className="block-reviews__actions">
            <Btn
              to={button_link}
              type="web"
              target="_blank"
              appearance="primary"
            >
              {button_label}
            </Btn>
          </div>
          <div className="block-reviews__slider__actions">
            <Btn appearance="arrow" classes={`reviews-prev`}>
              <Icon icon="arrow" />
            </Btn>
            <Btn appearance="arrow" classes={`reviews-next`}>
              <Icon icon="arrow" />
            </Btn>
          </div>
        </div>
      </div>
    </>
  );
};

export default Reviews;
