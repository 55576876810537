const breakPoints = {
  sm: "700px",
  md: "992px",
};

const checkBreakpoint = () => {
  if (typeof window === "undefined") return false;

  return {
    mobile: window.matchMedia(
      `(min-width: 0px) and (max-width: ${breakPoints.sm})`
    ),
    tablet: window.matchMedia(
      `(min-width: ${breakPoints.sm}) and (max-width: ${breakPoints.md})`
    ),
  };
};

const isMobile = checkBreakpoint().mobile?.matches;
const isTablet = checkBreakpoint().tablet?.matches;

export { isMobile, isTablet };
