import React from "react";
import Btn from "web/components/atoms/Btn";
import RichText from "web/components/atoms/RichText/RichText";
import { useLinkResolver } from "web/hooks";
import { Link } from "react-router-dom";
import { isMobile } from "web/components/helpers/mediaQueries/mediaQueries";
import Slider from "web/components/organisms/Slider/Slider";
import sliderConf from "./sliderConf";

const Card = ({ data, cardsBgColor, cardsTextColor }) => {
  const {
    title,
    description,
    image,
    button_label,
    link,
    bg_color,
    text_color,
  } = data;

  const style = {
    color: text_color || cardsTextColor || "inherit",
    backgroundColor: bg_color || cardsBgColor || "white",
  };
  console.log(style);

  const url = useLinkResolver(link);
  return (
    <div className="block-cards__card" style={style}>
      <div className="block-cards__card__inner">
        <Link className="block-cards__card__image" to={url}>
          <div className="block-cards__card__image__ctn">
            <img src={image.url} alt={image.alt} />
          </div>
        </Link>

        <div className="block-cards__card__content">
          <div className="block-cards__card__content__info">
            <Link to={url}>
              <h3 className="block-cards__card__title">{title}</h3>
            </Link>
            <div className="block-cards__card__description">
              <RichText content={description} />
            </div>
          </div>
          <div>
            <Btn to={url} appearance="link" classes="block-cards__card__link">
              {button_label}
            </Btn>
          </div>
        </div>
      </div>
    </div>
  );
};

const Cards = ({ content }) => {
  const { items, primary } = content;

  const {
    title,
    block_size,
    desktop_gap,
    mobile_gap,
    items_desktop,
    items_mobile,
    bg_color,
    text_color,
    padding_top,
    padding_bottom,
    cards_bg_color,
    cards_text_color,
  } = primary;

  console.log(primary);

  const [itemsQty, setItemsQty] = React.useState(items_desktop);
  const [gap, setGap] = React.useState(mobile_gap);

  React.useEffect(() => {
    setItemsQty(isMobile ? items_mobile || 1 : items_desktop || 3);
    setGap(isMobile ? mobile_gap || 10 : desktop_gap || 10);
  }, []);

  const style = {
    color: text_color || "inherit",
    backgroundColor: bg_color || "transparent",
    paddingTop: padding_top || 0,
    paddingBottom: padding_bottom || 0,
  };
  return (
    <>
      <div className="block-cards" style={style}>
        <div className={`block-cards__inner ctn ctn-${block_size}`}>
          <h2>{title}</h2>

          <div className="block-cards__items">
            <Slider
              sliderConf={{
                ...sliderConf,
                slidesPerView: itemsQty || 3,
                spaceBetween: gap || 10,
              }}
              items={items}
              ItemComponent={Card}
              cardsBgColor={cards_bg_color}
              cardsTextColor={cards_text_color}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Cards;
