import React from "react";
import image from "web/assets/img/sample2.jpg";
import Image from "web/components/atoms/Image";
import Btn from "web/components/atoms/Btn";
import RichTextComponent from "web/components/atoms/RichText/RichText";

const Hero = ({ content }) => {
  console.log(content);

  const { title, text, image } = content.primary;
  return (
    <>
      <div className="block-hero">
        <div className="block-hero__left">
          <div className="block-hero__left__inner">
            <div className="block-hero__left__content">
              <div className="block-hero__left__content__inner">
                <h1 className="block-hero__left__content__title">{title}</h1>
                <div className="block-hero__left__content__text">
                  <RichTextComponent content={text} />
                </div>
                <div className="block-hero__left__content__actions">
                  <Btn
                    appearance="white"
                    icon="call"
                    to="tel:+33 6 38 32 58 45"
                    type="web"
                  >
                    Appeler
                  </Btn>
                  <Btn
                    appearance="primary"
                    icon="schedule"
                    to="https://www.doctolib.fr/chiropracteur/bressuire/adrien-rollet/booking?utm_campaign=website-button&amp;utm_source=adrien-rollet-website-button&amp;utm_medium=referral&amp;utm_content=withoutpreview-blue-floating-bottom-right&amp;utm_term=adrien-rollet"
                    type="web"
                    target="_blank"
                  >
                    Prendre RDV
                  </Btn>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="block-hero__right">
          <div className="block-hero__right__inner">
            <Image image={image} />
            <div className="block-hero__right__actions">
              <Btn
                appearance="white"
                icon="call"
                to="tel:+33 6 38 32 58 45"
                type="web"
              >
                Appeler
              </Btn>
              <Btn
                appearance="primary"
                icon="schedule"
                to="https://www.doctolib.fr/chiropracteur/bressuire/adrien-rollet/booking?utm_campaign=website-button&amp;utm_source=adrien-rollet-website-button&amp;utm_medium=referral&amp;utm_content=withoutpreview-blue-floating-bottom-right&amp;utm_term=adrien-rollet"
                type="web"
                target="_blank"
              >
                Prendre RDV
              </Btn>
            </div>
          </div>
        </div>
        <svg class="filter" xmlns="http://www.w3.org/2000/svg">
          <defs>
            <filter id="filter">
              <feGaussianBlur
                in="SourceGraphic"
                stdDeviation="8"
                result="blur"
              />
              <feColorMatrix
                in="blur"
                mode="matrix"
                values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9"
                result="flt_tag"
              />
              <feComposite in="SourceGraphic" in2="filter" operator="atop" />
            </filter>
          </defs>
        </svg>
      </div>
    </>
  );
};

export default Hero;
