import React from "react";
import Slider from "web/components/organisms/Slider/Slider";
import RichTextComponent from "web/components/atoms/RichText/RichText";

const sliderConf = {
  spaceBetween: 0,
  slidesPerView: 1,
  loop: true,
  speed: 1000,
  navigation: true,
  pagination: {
    clickable: true,
  },
};

const ItemComponent = ({ data }) => {
  const { image } = data;
  return <img src={image?.url} alt={image?.alt} />;
};

export default function ImagesSlider({ content }) {
  const { items, primary } = content;

  const {
    text,
    block_size,
    bg_color = "transparent",
    text_color = "inherit",
    padding_top = 0,
    padding_bottom = 0,
  } = primary;

  const style = {
    color: text_color,
    backgroundColor: bg_color,
    paddingTop: padding_top,
    paddingBottom: padding_bottom,
  };
  return (
    <div className="block-images-slider" style={style}>
      <div className={`block-images-slider__inner ctn ctn-${block_size}`}>
        <Slider
          sliderConf={sliderConf}
          items={items}
          ItemComponent={ItemComponent}
        />
        <div className="block-images-slider__text">
          <RichTextComponent content={text} />
        </div>
      </div>
    </div>
  );
}
