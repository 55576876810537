import React from "react";
import { isMobile } from "web/components/helpers/mediaQueries/mediaQueries";

const Item = ({ data, itemWidth, itemPadding }) => {
  const style = {
    padding: itemPadding / 2,
    width: `${itemWidth}%`,
  };

  return (
    <div className="block-reinsurance__item" style={style}>
      <div className="block-reinsurance__item__image">
        <div className="block-reinsurance__item__image__inner">
          <img src={data.image.url} alt={data.image.alt} />
        </div>
      </div>
      <div className="block-reinsurance__item__label">
        <label>{data.text}</label>
      </div>
    </div>
  );
};

export default function Reinsurance({ content }) {
  const { items, primary } = content;

  const {
    title,
    block_size,
    desktop_gap,
    mobile_gap,
    items_desktop = 3,
    items_mobile = 1,
    bg_color = "transparent",
    text_color = "inherit",
    padding_top = 0,
    padding_bottom = 0,
  } = primary;

  const [itemWidth, setItemWidth] = React.useState(100 / items_desktop);
  const [itemPadding, setItemPadding] = React.useState(desktop_gap);

  React.useEffect(() => {
    setItemWidth(isMobile ? 100 / items_mobile : 100 / items_desktop);
    setItemPadding(isMobile ? mobile_gap : desktop_gap);
  }, []);

  const style = {
    color: text_color,
    backgroundColor: bg_color,
    paddingTop: padding_top,
    paddingBottom: padding_bottom,
  };

  return (
    <div className="block-reinsurance" style={style}>
      <div className={`block-reinsurance__inner  ctn ctn-${block_size}`}>
        <h2 className="block-reinsurance__title">{title}</h2>
        <div className="block-reinsurance__items">
          {items.map((item, key) => (
            <Item
              key={key}
              data={item}
              itemWidth={itemWidth}
              itemPadding={itemPadding}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
