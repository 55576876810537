import React from "react";
import { isMobile } from "web/components/helpers/mediaQueries/mediaQueries";

const Separator = ({ content }) => {
  const {
    block_size,
    desktop_height,
    mobile_height,
    with_line,
    line_position,
    line_color,
  } = content.primary || {};

  const style = {
    height: isMobile ? mobile_height : desktop_height,
  };
  return (
    <div className="block-separator2" style={style}>
      <div
        className={`block-separator2__inner ctn ctn-${block_size} ${line_position}`}
      >
        {with_line && (
          <div
            className="block-separator2__line"
            style={line_color && { backgroundColor: line_color }}
          ></div>
        )}
      </div>
    </div>
  );
};

export default Separator;
