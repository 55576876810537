import React from "react";

const Image = ({ content }) => {
  const { items, primary } = content;

  const {
    title,
    block_size,
    bg_color = "transparent",
    text_color = "inherit",
    padding_top = 0,
    padding_bottom = 0,
  } = primary;

  const style = {
    color: text_color,
    backgroundColor: bg_color,
    paddingTop: padding_top,
    paddingBottom: padding_bottom,
  };

  return (
    <div className="block-images" style={style}>
      <div className={`lock-images__inner ctn ctn-${block_size}`}>
        <h2>{title}</h2>
        <div className="lock-images__items">
          {items.map((item, key) => {
            return <img src={item.image.url} alt={item.image.alt} />;
          })}
        </div>
      </div>
    </div>
  );
};

export default Image;
