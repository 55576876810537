import React from "react";
import RichTextComponent from "web/components/atoms/RichText/RichText";

const RichText = ({ content }) => {
  const {
    content: text,
    block_size,
    bg_color = "transparent",
    text_color = "inherit",
    text_align = "left",
    padding_top = 0,
    padding_bottom = 0,
  } = content.primary;

  const style = {
    color: text_color,
    textAlign: text_align,
    backgroundColor: bg_color,
    paddingTop: padding_top,
    paddingBottom: padding_bottom,
  };

  return (
    <div className={`block-richtext`} style={style}>
      <div className={`block-richtext__inner ctn ctn-${block_size}`}>
        <RichTextComponent content={text} />
      </div>
    </div>
  );
};

export default RichText;
