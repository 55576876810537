export default {
  article_presentation: (data) => {
    return {
      ...data.primary,
    };
  },
  page_title: (data) => {
    return {
      ...data.primary,
    };
  },
};
