import React from "react";
import { InputText } from "web/components/atoms/Form/Input";
import AlertMsg from "web/components/atoms/AlertMsg/AlertMsg";
import contactDataService from "server/dataServices/Contact";

const ContactForm = ({ content }) => {
  const { block_size } = content.primary;

  const formRef = React.useRef(null);
  const [alertMessage, setAlertMessage] = React.useState(null);
  const [alertAppareance, setAlertAppareance] = React.useState(null);
  const [sending, setSending] = React.useState(false);

  const sendContact = (e) => {
    e.preventDefault();
    const form = formRef.current;
    const data = new FormData(form);
    setSending(true);
    contactDataService
      .sendContact(data)
      .then((res) => {
        setAlertMessage("Votre message a été envoyé avec succès. :)");
        setAlertAppareance("success");
        form.reset();
        setSending(false);
      })
      .catch((err) => {
        setAlertMessage("Une erreur s'est produite lors de l'envoi. :(");
        setAlertAppareance("danger");
        setSending(false);
      });
  };
  return (
    <div className="block-contact-form">
      <div className={`block-contact-form__inner ctn ctn-${block_size}`}>
        {alertMessage && (
          <AlertMsg message={alertMessage} appareance={alertAppareance} />
        )}
        <form ref={formRef}>
          <InputText name="name" placeholder="Nom Prénom *" />
          <InputText name="phone" placeholder="N° de téléphone (recommandé)" />
          <InputText name="email" placeholder="Email *" />
          <InputText name="subject" placeholder="Objet" />
          <textarea
            className="input-text textarea required"
            name="message"
            placeholder="Message"
          ></textarea>
          <button className="btn btn-primary" onClick={sendContact}>
            ENVOYER
          </button>
        </form>

        {sending && "Envoi en cours..."}
      </div>
    </div>
  );
};

export default ContactForm;
